import { render, staticRenderFns } from "./fras_libres_F_item.vue?vue&type=template&id=72813530&scoped=true"
import script from "./fras_libres_F_item.vue?vue&type=script&lang=js"
export * from "./fras_libres_F_item.vue?vue&type=script&lang=js"
import style0 from "./fras_libres_F_item.vue?vue&type=style&index=0&id=72813530&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72813530",
  null
  
)

export default component.exports