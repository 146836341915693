<!-- Finder Facturas Expedientes -->
<template>
  <div :class="`item ${selected? 'selected':''}`">    
    <div @click="clickItem">  
      <div class="conflex">
        <div class="column" style="width:10%">
          <v-btn icon @click="clickBtn(0)"><v-icon small color="red">{{ 'mdi-eye' }}</v-icon></v-btn>
          <v-btn icon @click="clickBtn('pdf')"><v-icon small color="red">{{ 'mdi-file-pdf-outline' }}</v-icon></v-btn>
        </div>

        <div class="column" style="width:10%">
          {{ source.fra_doc }}         
        </div>

        <div class="column" style="width:10%">
          {{ source.fra_fh|fecha }}         
        </div>

        <div class="column" style="width:10%">
          {{ source.imp }}         
        </div>

        <div class="column" style="width:50%">
          {{ source.name_cta_id }}         
        </div>

        <div class="column" style="width:5%">
          {{ source.fra_conta_asi }}         
        </div>

        <div class="column" style="width:5%">
          {{ source.pagada }}         
        </div>
      </div>     
    </div>
  </div>
</template>



<script>
export default {
  name: 'fras_Exp_F_item',
  props: {
    source: { type: Object, default() { return {}; }},
    selectedItem: { type: Object, default() { return {}; }}   
  },


  computed: {
    selected() {
      if (!this.selectedItem || !this.selectedItem.id) return false;
      if (this.selectedItem.id== this.source.id) return true;      
      return false;
    }
  },


  methods: {
    clickItem() {},


    // acciones de los botones de cada fila
    clickBtn(accion) {      
      this.emitEvent({ accion:accion, row:this.source });
    },


    // fn para emitir evento al padre
    emitEvent(payload) {
      let parent = this.$parent || this.$root
      let name = parent.$options.name

      while (parent && (!name || name != 'fras_libres_F')) {
        parent = parent.$parent
        if (parent) {
          name = parent.$options.name
        }
      }

      if (parent) parent.$emit.apply(parent, ['vsEvent'].concat(payload));
    }
  }
}
</script>


<style scoped>
  .item {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    border-bottom: 1px solid lightgrey;
    padding: 1em;
  }

  .selected {
    background-color: lightgrey;
  }

</style>